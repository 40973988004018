import { h } from 'preact';
import {useCallback, useEffect, useRef, useState} from 'preact/hooks'
import {usePopup} from "./usePopup";
import {ChatBubbles} from "./ChatBubbles";
import CSS from "./app.css";
export function App() {
	const [content, setContent] = useState("")
	const [author, setAuthor] = useState("")
	useEffect(() => {
		if (localStorage) {
			setContent(localStorage.getItem("lastContent") ?? "")
			setAuthor(localStorage.getItem("lastAuthor") ?? "")
		}
	}, []);
	const bubbles = useRef<null|HTMLDivElement>(null)
	const [popupHost, showPopup] = usePopup<HTMLDivElement>()
	const helpDialog = useRef<null|HTMLDialogElement>(null)

	const onContentChange = useCallback((ev: Event) => {
		setContent((ev.currentTarget as HTMLTextAreaElement).value)
		localStorage.setItem("lastContent", (ev.currentTarget as HTMLTextAreaElement).value)
	}, [setContent])
	const onAuthorChange = useCallback((ev: Event) => {
		setAuthor((ev.currentTarget as HTMLInputElement).value)
		localStorage.setItem("lastAuthor", (ev.currentTarget as HTMLInputElement).value)
	}, [setAuthor])
	const copyHtml = useCallback(async () => {
		try {
			const t = await new Promise<string>((resolve, reject) => {
				const el = bubbles.current;
				if (!el) {
					reject(new Error("Chatbubbles were not yet loaded."));
					return
				}
				const copy = el.cloneNode(true) as HTMLDivElement;
				for (const element of Array.from(copy.querySelectorAll("div.markup"))) {
					const parent = element.parentElement;
					if (!parent) {
						continue;
					}
					while (element.firstChild) {
						parent.insertBefore(element.firstChild, element)
					}
					element.remove()
				}
				resolve(copy.innerHTML);
			});
			await navigator.clipboard.writeText(t);
			await showPopup(`Copied HTML to clipboard.`);
		} catch (e) {
			console.error(e);
			try {
				await showPopup(`Problem copying HTML: ${e instanceof Error ? e.message : e}`, "error");
			} catch (ex) {
				console.error(ex);
			}
		}
	}, [bubbles, showPopup])
	const showHelp = useCallback(() => {
		const d = helpDialog.current
		if (!d) {
			console.error("Failed to show dialog")
			return
		}
		d.showModal()
	}, [helpDialog])

	return <div id={CSS.app} ref={popupHost}>
		<div id={CSS.input}>
			<div id={CSS.author}>
				<label id={CSS.authorLabel} for={CSS.authorInput}>User's name:</label>
				<input id={CSS.authorInput} type="text" onInput={onAuthorChange} value={author} />
			</div>
			<div id={CSS.contents}>
				<label id={CSS.contentsLabel} for={CSS.contentsInput}>Message contents:</label>
				<textarea id={CSS.contentsInput} onInput={onContentChange}>{content}</textarea>
			</div>
			<button type="button" onClick={copyHtml}>Copy HTML</button>
			<button type="button" onClick={showHelp}>Show Help</button>
			<a class={CSS.workSkinButton} href="https://gist.github.com/deliciousreya/1b6c61fde4b500e27c0901ed6ff98ac9" rel="external noopener nofollow">Work Skin CSS</a>
		</div>
		<div id={CSS.output} ref={bubbles}>
			<ChatBubbles text={content} selfAuthor={author} />
		</div>
		<dialog id={CSS.helpDialog} ref={helpDialog}>
			<form method="dialog" class={CSS.helpContents}>
				<h2>Help</h2>
				<blockquote className="chatbubbles">
					<div class="header">
						<p class="chatname">
							# Header 1
						</p>
						<p class="subtitle">
							## Header 2 (put these first)
						</p>
					</div>
					<div class="system">
						<p>System message</p>
					</div>
					<p class="datestamp">
						@ Timestamp
					</p>
					<p class="chatauthor self">
						User
					</p>
					<div class="chatbubble self">
						<p>User: Message</p>
					</div>
					<p class="chatauthor other">
						Other User
					</p>
					<div class="chatbubble other">
						<p>Other User: Use **<strong>Markdown</strong>** for styling</p>
					</div>
					<div class="chatbubble other">
						<p>Other User: Paste 😃 emoji from <a href="https://emojipedia.org/"
																				 rel="external nofollow noopener">Emojipedia</a>
						</p>
					</div>
					<div class="chatbubble other emojionly">
						<p>😃</p>
					</div>
					<p class="chatauthor self">
						User
					</p>
					<div class="chatbubble self">
						<p>User: Continuation<br />
							&gt;&gt;<br />
							&gt;&gt; Additional lines</p>
					</div>
					<p class="chatauthor other">
						Other User
					</p>
					<div class="chatbubble other">
						<p>Other User: (image)</p>
					</div>
					<div class="chatbubble loading other" />
					<p class="chatauthor self">
						User
					</p>
					<div class="chatbubble self">
						<p>User: (...)</p>
					</div>
					<div class="chatbubble typing self"><p>● ● ●</p></div>
					<div class="fakeTextbox">
						<p>[Textbox with <span class="insertionPoint" />|insertion point]</p>
					</div>
				</blockquote>
				<button autofocus>Close</button>
			</form>
		</dialog>
	</div>
}

export default App;
