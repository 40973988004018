import CSS from './usePopup.css'
import { type MutableRef, useCallback, useRef } from 'preact/hooks';

export function usePopup<T extends HTMLElement>(): [hostRef: MutableRef<T|null>, triggerPopup: (text: string, className?: 'success'|'info'|'warning'|'error') => Promise<boolean>] {
	const hostRef = useRef<T|null>(null)
	const showPopup = useCallback(async (text: string, className?: 'success'|'info'|'warning'|'error'): Promise<boolean> => {
		const host = hostRef.current
		if (!host) {
			console.log("no host, no party")
			return false
		}
		host.classList.add(CSS.jsPopupHost)
		const container = host.ownerDocument.createElement("div")
		container.classList.add(CSS.jsPopupContainer)
		host.appendChild(container)
		const popup = host.ownerDocument.createElement("div")
		popup.classList.add(CSS.jsPopup)
		if (className) {
			popup.classList.add(CSS[className])
		}
		popup.innerText = text
		container.appendChild(popup)
		return new Promise((resolve) => {
			const removePopup = () => {
				container.removeChild(popup)
				host.removeChild(container)
				resolve(true)
			}
			popup.addEventListener('animationend', removePopup)
			popup.addEventListener('animationcancel', removePopup)
		})
	}, [hostRef])
	return [hostRef, showPopup]
}
